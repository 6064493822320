import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getCandidate } from "../../services/getCandidate";
import LoadingComponent from "../../components/LoadingComponent";
import ErrorDialog from "../../components/ErrorDialog";
import CustomButton from "../../components/CustomButton";
import defaultProfilePicture from "../../assets/default_profile_picture.jpg";
import { Candidate } from "../../../api/src/interfaces/Candidate";
export default function PublicCandidateResume() {
  const [searchParams, _] = useSearchParams();
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [candidate, setCandidate] = useState<Candidate>();
  useEffect(() => {
    const candidateId = searchParams.get("candidateId");
    const responseSheetName = searchParams.get("responseSheetName");
    if (!candidateId || !responseSheetName) {
      setErrMsg("Invalid candidate link");
      setIsLoading(false);
      return;
    }

    getCandidate(candidateId, responseSheetName)
      .then(({ data, error }) => {
        if (error) throw new Error(error);
        setCandidate(data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setErrMsg("An unknown error occured");
        setIsLoading(false);
      });
  }, []);
  return (
    <SimpleIphonePromptLayout>
      <ErrorDialog errMsg={errMsg} setErrMsg={setErrMsg}></ErrorDialog>
      {isLoading ? (
        <LoadingComponent></LoadingComponent>
      ) : (
        <>
          <CustomButton
            style={{
              color: "#e1436c",
              backgroundColor: "white",
              //   fontSize: "2rem",
              fontWeight: "bold",
            }}
          >
            Guest Name
          </CustomButton>
          <div
            style={{
              maxWidth: "250px",
              maxHeight: "250px",
              borderRadius: "50%",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{ maxWidth: "250px", maxHeight: "250px" }}
              src={defaultProfilePicture}
              alt="profile"
            ></img>
          </div>
          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
            {candidate?.name}
          </div>
          <CustomButton
            onClick={() => {
              window.open(candidate?.resume);
            }}
            style={{ color: "white", backgroundColor: "#e1436c" }}
          >
            Click here to download recording
          </CustomButton>
        </>
      )}
    </SimpleIphonePromptLayout>
  );
}

import celebrateVertical from "./../../assets/celebrate-vertical.png";
import { Link } from "react-router-dom";
export default function WalkinCheckInSuccess() {
  return (
    <>
      <div
        style={{
          gridArea: "2 / 2 / 3 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "48px", marginBottom: "20px" }}>
          You have
          <br />
          successfully
          <br />
          check in!
        </div>
        <div style={{ color: "#fcce53" }}>
          APT wishing you <br />
          good interview.
        </div>
      </div>
      <div
        style={{
          gridArea: "2 / 1 / 5 / 4",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{
            maxWidth: "100%",
          }}
          src={celebrateVertical}
          alt="celebrateVertical"
        ></img>
      </div>
      <div
        style={{
          gridArea: "2 / 2 / 5 / 3",
          display: "flex",
          alignItems: "end",
        }}
      >
        <Link
          to={"/walkin/myresume"}
          style={{
            textDecoration: "none",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "#e1436c",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Next
          </div>
        </Link>
      </div>
    </>
  );
}

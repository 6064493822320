import bannerImg from "./../../assets/layout-header.png";
import loveLetterImg from "./../../assets/loveletter.png";

import { useCallback, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import LoadingComponent from "./../../components/LoadingComponent";
import { Link } from "react-router-dom";
import { getCandidate } from "../../services/getCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { Candidate } from "../../../api/src/interfaces/Candidate";
import useSettings from "../../components/useSettings";
export default function TicketRedeemPage() {
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  let [urlParams, setUrlParams] = useSearchParams();
  let [candidateData, setCandidateData] = useState<Partial<Candidate>>({});
  const settings = useSettings(true);
  const jobInfoOnClick = useCallback(() => {
    if (!settings) return;
    window.open(settings.setting_pwa_job_info);
  }, [settings]);

  useEffect(() => {
    console.log("candidateData", candidateData);
  }, [candidateData]);

  useEffect(() => {
    let uid = getUID();
    if (!uid) {
      navigate("/");
      return;
    }
    getCandidate(uid, getResponseSheetName())
      .then(({ data, error }) => {
        console.log("data", data);
        if (error) {
          throw new Error(error);
        }
        if (data.checkInStatus === "checkedin") {
          navigate("/walkin/interview");
        }

        setCandidateData(data);
      })
      .catch((err) => console.error(err));
    setIsLoading(false);
  }, []);
  return isLoading ? (
    <LoadingComponent style={{ gridColumn: "2 / 3" }}></LoadingComponent>
  ) : (
    <>
      <div
        style={{
          gridArea: "2 / 2 / 3 / 3",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <h1 style={{ fontSize: "32px", fontWeight: "bold", color: "red" }}>
            WELCOME!
          </h1>
        </div>
        <div
          style={{
            borderRadius: "30px",
            border: "3px solid white",
            backgroundColor: "#e1436c",
            padding: "40px 30px",
            marginLeft: "10px",
            marginRight: "10px",
            // height: "100%",
            color: "black",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              textAlign: "center",
              fontSize: "large",
              color: "white",
            }}
          >
            You have been cordially invited to our
            <br />
            "我们的妈妈生日" Celebration
          </div>
          <div style={{ width: "100%", textAlign: "center" }}>
            <img
              alt="ticket-img"
              style={{
                maxHeight: "200px",
                maxWidth: "100%",
                marginBottom: "20px",
              }}
              src={loveLetterImg}
            ></img>
          </div>
          <div
            style={{
              // color: "#dfb3de",
              color: "white",
              marginBottom: "10px",
            }}
          >
            Event Details
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateRows: "auto 1fr",
              gridTemplateColumns: "1fr 1fr",
              marginBottom: "10px",
            }}
          >
            <div style={{ color: "white" }}>Date / Time</div>
            <div style={{ color: "white" }}></div>
            <div style={{ color: "#fff2cc" }}>
              14th Dec 2024 (Saturday) {candidateData.interviewDateTime}
            </div>
            <div></div>
          </div>
          <div
            onClick={jobInfoOnClick}
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "#e1436c",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Click Me If You Love Mum!
          </div>
        </div>
      </div>
      <div
        style={{
          gridColumn: "2 / 3",
          margin: "0 10px",
          marginBottom: "20px",
        }}
      >
        <Link to="/walkin/ready" style={{ textDecoration: "none" }}>
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              color: "#e1436c",
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            Let's Go
          </div>
        </Link>
      </div>
      {/* <div style={{ height: "20px", gridArea: "4 / 1 / 4 / 3" }}></div> */}
    </>
  );
}

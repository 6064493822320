import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import React, { useState, useEffect } from "react";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { getQuizState, setQuizWinner } from "../../services/quiz";
import useCandidate from "../../components/useCandidate";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WalkinInterviewForm() {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
  });
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);
  const candidate = useCandidate();

  const [errOpen, setErrOpen] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState("");

  const errHandleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setErrOpen(false);
  };

  const onSubmit = async () => {
    setSubmitting(true);
    let uid = getUID();
    if (!uid) {
      setSubmitting(false);
      navigate("/");
      return;
    }
    try {
      const data = await getQuizState();
      let { winnerCode: checkInCode, hasWinner, winnerJson } = data;
      const uid = getUID();
      if (
        hasWinner &&
        winnerJson?.claimed &&
        winnerJson.id &&
        winnerJson.id === uid
      ) {
        setErrMsg("Please wait for next round...");
        setErrOpen(true);
        return;
      }
      if (hasWinner && winnerJson && winnerJson.id !== uid) {
        setErrMsg("Sorry! There was a winner before you");
        setErrOpen(true);
        return;
      }
      if (String(checkInCode) !== String(formData.code)) {
        throw new Error("Wrong Answer");
      }
      await setQuizWinner({ winnerId: uid, name: String(candidate?.name) });
      console.log("Success");
      navigate("/walkin/wheel");
    } catch (err: any) {
      console.error(err);
      setErrMsg(err.message);
      setErrOpen(true);
    } finally {
      setSubmitting(false);
    }
  };
  useEffect(() => {
    (async () => {
      try {
        const uid = getUID();
        const data = await getQuizState();
        if (
          data.hasWinner &&
          data.winnerJson?.id === uid &&
          !data.winnerJson?.claimed
        ) {
          navigate("/walkin/wheel");
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <SimpleIphonePromptLayout>
      <Snackbar open={errOpen} autoHideDuration={6000} onClose={errHandleClose}>
        <Alert onClose={errHandleClose} severity="error" sx={{ width: "100%" }}>
          {errMsg}
        </Alert>
      </Snackbar>
      <div>Are you ready to "win prizes"?</div>
      {/* <div style={{ color: "#e1436c", fontWeight: "bold" }}>Prize (RM)</div> */}
      {/* <input
        placeholder="Amount"
        type="text"
        onChange={(evt) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              name: evt.target.value,
            };
          });
        }}
        style={{
          width: "100%",
          textAlign: "center",
          padding: "10px",
          borderRadius: "15px",
          border: "0 solid rgba(0,0,0,0)",
          outline: "3px solid #e1436c",
        }}
      ></input> */}
      <div style={{ color: "#e1436c", fontWeight: "bold" }}>My Answer</div>
      <input
        placeholder="NO. XXX"
        onChange={(evt) => {
          setFormData((prevState) => {
            return {
              ...prevState,
              code: evt.target.value,
            };
          });
        }}
        type="text"
        style={{
          width: "100%",
          textAlign: "center",
          padding: "10px",
          borderRadius: "15px",
          border: "0 solid rgba(0,0,0,0)",
          outline: "3px solid #e1436c",
        }}
      ></input>
      <div
        onClick={onSubmit}
        style={{
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: submitting ? "grey" : "#e1436c",
          borderRadius: "10px",
          padding: "10px",
          width: "100%",
        }}
      >
        {submitting ? "💖一起发💖" : "💖一起发💖"}
      </div>
    </SimpleIphonePromptLayout>
  );
}

import React, { useEffect, useState } from "react";
import decorTop from "./../../assets/layout-header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";
export default function WalkinInterviewSuccess() {
  const [rating, setRating] = useState(3);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  const submitRate = () => {
    setSubmitting(true);
    let uid = getUID();
    if (!uid) {
      console.error("Unable to get UID");
      setSubmitting(false);
      return;
    }

    updateCandidate(
      uid,
      {
        rating: String(rating),
      },
      getResponseSheetName(),
    )
      .then(() => {
        console.log("Done");
        setSubmitting(false);
        setSubmitted(true);
      })
      .catch((err) => {
        console.error(err);
        setSubmitting(false);
      });
  };

  return submitted ? (
    <>
      <div style={{ gridColumn: "1 / 4", gridRow: "2 / 2", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={decorTop}
            alt="decorTop"
            style={{
              maxHeight: "180px",
              maxWidth: "100%",
            }}
          ></img>
        </div>
      </div>
      <div
        style={{
          gridColumn: "2 / 3",
          gridRow: "2 / 2",
          backgroundColor: "rgba(255,255,255,0.7)",
          borderRadius: "10px",
          border: "3px solid #e1436c",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // height: "100%",
        }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "#e1436c",
            textAlign: "center",
            fontSize: "48px",
          }}
        >
          Rating submitted !
        </div>
      </div>
      <div
        onClick={() => {
          navigate("/walkin/end");
        }}
        style={{
          gridColumn: "2 / 3",
          gridRow: "4 / 4",
          marginTop: "-10%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: submitting ? "grey" : "#e1436c",
          borderRadius: "10px",
          padding: "10px",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        Next
      </div>
    </>
  ) : (
    <>
      <div style={{ gridColumn: "1 / 4", gridRow: "2 / 2", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={decorTop}
            alt="decorTop"
            style={{
              maxHeight: "180px",
              maxWidth: "100%",
            }}
          ></img>
        </div>
      </div>
      <div
        style={{ gridColumn: "2 / 3", gridRow: "2 / 2", color: "#e1436c" }}
        className="zoom-out-anim"
      >
        <div
          style={{
            backgroundColor: "rgba(255,255,255,0.7)",
            borderRadius: "10px",
            border: "3px solid #e1436c",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div style={{ fontSize: "2.5rem", textAlign: "center" }}>
            Congratulations!
          </div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>You </div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>are the</div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>winner !!</div>
          <div style={{ fontSize: "2rem", textAlign: "center" }}>You won</div>
          <div
            style={{
              fontSize: "2rem",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            RM {localStorage.getItem("win")}
          </div>
          <div
            style={{
              marginBottom: "30px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              columnGap: "10px",
            }}
          >
            {[1, 2, 3, 4, 5].map((x) => (
              <FontAwesomeIcon
                key={`rating-star-${x}`}
                onClick={() => setRating(x)}
                style={{
                  color: rating >= x ? "yellow" : "white",
                  cursor: "pointer",
                }}
                size={"2xl"}
                icon={["fas", "star"]}
              ></FontAwesomeIcon>
            ))}
          </div>
          <div>Rate the event!</div>
        </div>
      </div>
      <div style={{ gridColumn: "2 / 3" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
          }}
        >
          <div
            onClick={() => navigate("/walkin/interviewForm")}
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: submitting ? "grey" : "#e1436c",
              color: submitting ? "white" : "white",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          >
            Back
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "10px",
            width: "100%",
            gridColumn: "2 / 3",
          }}
        >
          <div
            onClick={submitRate}
            style={{
              cursor: "pointer",
              textAlign: "center",
              backgroundColor: submitting ? "grey" : "white",
              color: submitting ? "white" : "#e1436c",
              borderRadius: "10px",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            {submitting ? "Submiting" : "Submit rating & Exit"}
          </div>
        </div>
      </div>
    </>
  );
}

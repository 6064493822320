import React, { useState, useEffect } from "react";
import { SETTINGS } from "../../../api/src/interfaces/sheets";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import { getResponseSheetName } from "../../utils/authUtils";
import { Link } from "react-router-dom";

export default function WalkinLocation() {
  const [settingsData, setSettingsData] = useState<SETTINGS | null>(null);
  const [mapEmbedSRC, setMapEmbedSRC] = useState("");
  useEffect(() => {
    getSettings(getResponseSheetName())
      .then(({ data, error }) => {
        if (error) {
          throw new Error(error);
        }
        setSettingsData(data);
        setMapEmbedSRC(
          `https://maps.google.com/maps?width=100%&height=600&hl=en&q=${
            String(data.setting_walkin_checkin_latlong)
              .split(",")
              .map((x) => x.trim())[0]
          }, ${
            String(data.setting_walkin_checkin_latlong)
              .split(",")
              .map((x) => x.trim())[1]
          }&z=14&ie=UTF8&iwloc=B&output=embed`,
        );
        console.log(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  return (
    <SimpleIphonePromptLayout>
      <div style={{ fontSize: "48px" }}>Location</div>
      <div style={{ whiteSpace: "pre-wrap" }}>
        {settingsData
          ? String(settingsData.setting_walkin_checkin_address)
          : ""}
      </div>
      {settingsData ? (
        <div style={{ width: "100%", maxHeight: "200px" }}>
          <div style={{ width: "100%" }}>
            <iframe width="100%" height="200" scrolling="no" src={mapEmbedSRC}>
              <a href="https://www.maps.ie/distance-area-calculator.html">
                distance maps
              </a>
            </iframe>
          </div>
        </div>
      ) : (
        ""
      )}

      <Link to="/walkin/checkin">
        <div
          style={{
            width: "100%",
            cursor: "pointer",
            textAlign: "center",
            color: "#e1436c",
            backgroundColor: "#ececee",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Check in
        </div>
      </Link>

      <a
        target="_blank"
        href={settingsData ? settingsData.setting_walkin_checkin_gmap_link : ""}
        rel="noreferrer"
        style={{ width: "100%", textDecoration: "none" }}
      >
        <div
          // onClick={actionOnClick}
          style={{
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            backgroundColor: "#cc2802",
            borderRadius: "10px",
            padding: "10px",
            width: "100%",
          }}
        >
          Google Map
        </div>
      </a>
      <a
        target="_blank"
        href={settingsData ? settingsData.setting_walkin_checkin_waze_link : ""}
        rel="noreferrer"
        style={{ width: "100%", textDecoration: "none" }}
      >
        <div
          style={{
            cursor: "pointer",
            textAlign: "center",
            color: "black",
            backgroundColor: "#34ccfe",
            borderRadius: "10px",
            padding: "10px",
          }}
        >
          Waze
        </div>
      </a>
    </SimpleIphonePromptLayout>
  );
}

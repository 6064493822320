import React, { useEffect, useState } from "react";
import CustomButton from "../../components/CustomButton";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import {
  getQuizState,
  resetQuiz,
  updateRewardItems,
  updateWinnerCode,
} from "../../services/quiz";

function Red(props: { children: React.ReactNode }) {
  return <span style={{ color: "red" }}>{props.children}</span>;
}

export default function PublicConfig() {
  const [appState, setAppState] = useState<
    Partial<Awaited<ReturnType<typeof getQuizState>>>
  >({});
  const [rewardText, setRewardText] = useState("");

  const refetchStates = async () => {
    try {
      const state = await getQuizState();
      setAppState(state);
      setRewardText(JSON.stringify(state.rewardItems, null, 2));
    } catch (e) {
    } finally {
    }
  };

  useEffect(() => {
    (async () => {
      await refetchStates();
    })();
  }, []);
  return (
    <SimpleIphonePromptLayout>
      <div>
        Has winner?&nbsp;<Red>{Boolean(appState.hasWinner) ? "Yes" : "No"}</Red>
      </div>
      {appState.winnerJson?.id ? (
        <>
          <div>
            Winner ID: <Red>{appState.winnerJson?.id}</Red>
          </div>
          <div>
            Winner Name: <Red>{appState.winnerJson?.name}</Red>
          </div>
        </>
      ) : (
        <></>
      )}
      <div>
        Has Winner spin the wheel?&nbsp;
        <Red>{Boolean(appState.winnerJson?.claimed) ? "Yes" : "No"}</Red>
      </div>

      <div>
        <div>Reward items:</div>
        <textarea
          style={{ height: "300px" }}
          value={rewardText}
          onChange={(e) => {
            setRewardText(e.target.value);
          }}
        ></textarea>
      </div>
      <div>
        <div>Quiz Answer</div>
        <input
          onChange={(e) => {
            setAppState((old) => {
              return {
                ...old,
                winnerCode: e.target.value,
              };
            });
          }}
          value={appState.winnerCode}
          type="text"
          style={{
            width: "100%",
            textAlign: "center",
            padding: "10px",
            borderRadius: "15px",
            border: "0 solid rgba(0,0,0,0)",
            outline: "3px solid #e1436c",
          }}
        ></input>
      </div>

      <div
        onClick={() => {
          refetchStates();
        }}
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: "grey",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        Refresh
      </div>
      <div
        onClick={async () => {
          console.log({ appState, rewardText });
          if (appState.winnerCode) {
            await updateWinnerCode({ winnerCode: appState.winnerCode ?? "" });
          }

          try {
            const rewards = JSON.parse(rewardText);
            await updateRewardItems({ items: rewards });
          } catch (e) {
            alert("Reward items format error");
          }
          alert("Saved");
        }}
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        Save
      </div>
      <div
        onClick={async () => {
          let input = window.confirm("Are you sure to RESET the quiz?");
          if (!input) {
            return;
          }
          await resetQuiz();
          const state = await getQuizState();
          setAppState(state);
          alert("Done");
        }}
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: "red",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        Reset
      </div>
    </SimpleIphonePromptLayout>
  );
}

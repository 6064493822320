import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import rootUrl from "../../APIRoot";
import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import {
  getDistanceFromLatLonInKm,
  getUserLocationAsync,
} from "../../utils/browserUtils";
import mailOpenedImage from "./../../assets/mailOpenedImage.png";
export default function WalkinReadySuccess() {
  const [errMsg, setErrMsg] = useState("");
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [checkingIn, setCheckingIn] = useState(false);
  const navigate = useNavigate();
  const handleErrorDialogOnClose = () => {
    setErrorDialogOpen(false);
  };

  const onCheckIn = () => {
    navigate("/walkin/myresume");
  };

  useEffect(() => {}, []);
  return (
    <SimpleIphonePromptLayout>
      <Dialog open={errorDialogOpen} onClose={handleErrorDialogOnClose}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errMsg}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOnClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          padding: "40px 0",
          fontWeight: "bold",
          fontSize: "32px",
        }}
      >
        You have
        <br />
        successfully
        <br />
        check-in !
        <br />
        <br />
        <img
          src={mailOpenedImage}
          style={{ maxHeight: "100px" }}
          alt="love"
        ></img>
      </div>

      <div
        onClick={onCheckIn}
        style={{
          width: "100%",
          cursor: "pointer",
          textAlign: "center",
          color: "white",
          backgroundColor: checkingIn ? "grey" : "#e1436c",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        {checkingIn ? "Let's Go!" : "Let's Go!"}
      </div>
    </SimpleIphonePromptLayout>
  );
}

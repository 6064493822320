import rootUrl from "../APIRoot";
import { getUID } from "../utils/authUtils";

export async function getQuizState(): Promise<{
  hasWinner: boolean;
  winnerJson?: { id: string; claimed: boolean, name: string };
  winnerCode: string;
  rewardItems: string[];
}> {
  let req = await fetch(`${rootUrl}/quiz/state`, {
    method: "GET",
  });

  return await req.json();
}

export async function setQuizWinner(body: { winnerId: string, name: string }): Promise<{
  done: boolean;
}> {
  let req = await fetch(`${rootUrl}/quiz/setWinner`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ winnerId: body.winnerId, name: body.name }),
  });

  return await req.json();
}

export async function resetQuiz(): Promise<{ done: boolean }> {
  let req = await fetch(`${rootUrl}/quiz/reset`, {
    method: "POST",
  });

  return await req.json();
}

export async function recordWin(body: {
  item: String;
}): Promise<{ id: string }> {
  const uid = getUID();
  let req = await fetch(`${rootUrl}/quiz/recordWins`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ item: body.item, uid }),
  });

  return await req.json();
}

export async function winnerClaimed() {
  let req = await fetch(`${rootUrl}/quiz/winnerClaimed`, {
    method: "POST",
  });

  return await req.json();
}

export async function updateWinnerCode(body: { winnerCode: string }) {
  let req = await fetch(`${rootUrl}/quiz/winnerCode`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ winnerCode: body.winnerCode }),
  });

  return await req.json();
}

export async function updateRewardItems(body: { items: string[] }) {
  let req = await fetch(`${rootUrl}/quiz/rewardItems`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ items: body.items }),
  });

  return await req.json();
}

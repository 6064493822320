import React, { useEffect, useState } from "react";
import decorTop from "./../../assets/layout-header.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { useNavigate } from "react-router-dom";
export default function WalkinEnd() {
  const [rating, setRating] = useState(3);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <div style={{ gridColumn: "1 / 4", gridRow: "2 / 2", marginTop: "20px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={decorTop}
            alt="decorTop"
            style={{
              maxHeight: "180px",
              maxWidth: "100%",
            }}
          ></img>
        </div>
      </div>
      <div
        style={{
          gridColumn: "2 / 3",
          gridRow: "2 / 2",
          backgroundColor: "rgba(255,255,255,0.7)",
          borderRadius: "10px",
          border: "3px solid #e1436c",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // height: "100%",
        }}
        className="zoom-out-anim"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "#e1436c",
            textAlign: "center",
            fontSize: "40px",
          }}
        >
          Hope you enjoy
          <br />
          the day!
          <br />
          <br />
          Thank you for
          <br />
          coming!
        </div>
      </div>
    </>
  );
}

import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import banner from "../assets/banner.png";
import { getResponseSheetName, getUID, logOff } from "../utils/authUtils";
import { getCandidate } from "../services/getCandidate";
import useCandidate from "../components/useCandidate";
import backgroundImage from "../assets/background.jpg";
export default function XmasLayout() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };
  const candidate = useCandidate();

  return (
    <div
      style={{
        color: "white",
        display: "grid",
        gridTemplateColumns: "20px 1fr 20px",
        gridTemplateRows: "auto 1fr auto",
        height: "100%",
        paddingTop: "20px",
        fontWeight: "bold",
        // backgroundColor: "red",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
      }}
    >
      <div></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40px 1fr 100px",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={["fas", "bars"]}
            ></FontAwesomeIcon>
          </IconButton>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <div
                style={{
                  padding: "20px",
                  color: "white",
                  backgroundColor: "#e1436c",
                }}
              >
                <img
                  onClick={() => {
                    toggleDrawer(false);
                    setTimeout(() => navigate("/walkin/checkin"), 500);
                  }}
                  src={banner}
                  style={{ maxWidth: "100%" }}
                  alt="side-banner"
                ></img>
                <div>Welcome!</div>
                <div style={{ fontWeight: "bold" }}>
                  {candidate ? candidate.name : ""}
                </div>
                {candidate ? (
                  <>
                    <div
                      onClick={logOff}
                      style={{ textDecoration: "underline" }}
                    >
                      Log off
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <Divider></Divider>
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleDrawer(false);
                      setTimeout(() => navigate("/walkin/checkin"), 500);
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        size="xl"
                        icon={["fas", "arrow-right-from-bracket"]}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Check in"} />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      toggleDrawer(false);
                      setTimeout(() => navigate("/walkin/location"), 500);
                    }}
                  >
                    <ListItemIcon>
                      <FontAwesomeIcon
                        size="xl"
                        icon={["fas", "location-dot"]}
                      />
                    </ListItemIcon>
                    <ListItemText primary={"Check in location"} />
                  </ListItemButton>
                </ListItem>
                {candidate ? (
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={() => {
                        toggleDrawer(false);
                        setTimeout(() => navigate("/walkin/myresume"), 500);
                      }}
                    >
                      <ListItemIcon>
                        <FontAwesomeIcon size="xl" icon={["fas", "file"]} />
                      </ListItemIcon>
                      <ListItemText primary={"Win prizes"} />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <></>
                )}
              </List>
            </Box>
          </Drawer>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={banner} alt="decorTop" style={{ maxHeight: "60px" }} />
        </div>
        <div>
          <Link to="/walkin/help" style={{ textDecoration: "none" }}>
            <Button
              sx={{ color: "#25D366", backgroundColor: "white !important" }}
              variant="contained"
              startIcon={
                <FontAwesomeIcon icon={["fab", "whatsapp"]}></FontAwesomeIcon>
              }
            >
              Help
            </Button>
          </Link>
        </div>
      </div>
      <div></div>
      <Outlet></Outlet>
    </div>
  );
}

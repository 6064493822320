import { Link, Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@mui/material";
import banner from "../assets/banner.png";
import backgroundImage from "../assets/background.jpg";
export default function PublicLayout() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const toggleDrawer = (open: boolean) => {
    setOpenDrawer(open);
  };

  return (
    <div
      style={{
        color: "white",
        display: "grid",
        gridTemplateColumns: "20px 1fr 20px",
        gridTemplateRows: "auto 1fr auto",
        height: "100%",
        // backgroundColor: "red",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        paddingTop: "20px",
        fontWeight: "bold",
      }}
    >
      <div></div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "40px 1fr 100px",
          alignItems: "center",
        }}
      >
        <div>
          <IconButton onClick={() => toggleDrawer(true)}>
            <FontAwesomeIcon
              style={{ color: "black" }}
              icon={["fas", "bars"]}
            ></FontAwesomeIcon>
          </IconButton>
          <Drawer
            anchor={"left"}
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
          >
            <Box
              sx={{ width: 250 }}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <div
                style={{
                  padding: "20px",
                  color: "white",
                  backgroundColor: "#e1436c",
                }}
              >
                <img
                  onClick={() => {
                    toggleDrawer(false);
                    setTimeout(() => navigate("/xmas/checkinevent"), 500);
                  }}
                  src={banner}
                  style={{ maxWidth: "100%" }}
                  alt="side-banner"
                ></img>
                <div>Welcome!</div>
              </div>
              <Divider></Divider>
              <List>{/* Empty sidebar */}</List>
            </Box>
          </Drawer>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={banner} alt="decorTop" style={{ maxHeight: "60px" }} />
        </div>
        <div></div>
      </div>
      <div></div>
      <Outlet></Outlet>
    </div>
  );
}

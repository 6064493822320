import rootUrl from "../APIRoot";
import { Candidate } from "../../api/src/interfaces/Candidate";

export async function getCandidate(
  cid: String,
  responseSheetName: string,
): Promise<{
  data: Candidate;
  error: any;
}> {
  let res = await fetch(`${rootUrl}/candidates/${responseSheetName}/${cid}`);
  let { data, error } = await res.json();
  if (data) {
    const singleCandidate = data[0];
    return { data: singleCandidate, error };
  }
  return { data, error };
}

import rootUrl from "../APIRoot";
import { Candidate } from "../../api/src/interfaces/Candidate";

export default async function updateCandidate(
  candidateId: string,
  newCandidateData: Partial<Candidate>,
  responseSheetName: string,
): Promise<{
  data: any;
  error: any;
}> {
  let req = await fetch(
    `${rootUrl}/candidates/${responseSheetName}/${candidateId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newCandidateData),
    },
  );

  let { data, error } = await req.json();
  return { data, error };
}

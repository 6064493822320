import SimpleIphonePromptLayout from "../../layouts/SimpleIphonePromptLayout";
import { getSettings } from "../../services/getSettings";
import React, { useState, useEffect } from "react";
import updateCandidate from "../../services/updateCandidate";
import { getResponseSheetName, getUID } from "../../utils/authUtils";
import { Link, useNavigate } from "react-router-dom";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { getQuizState, recordWin, winnerClaimed } from "../../services/quiz";
import { ISpinWheelProps, SpinWheel } from "../../components/SpinWheel";
import useCandidate from "../../components/useCandidate";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function WalkinSpinWheel() {
  const navigate = useNavigate();
  const [winText, setWinText] = useState("");
  const [winId, setWinId] = useState("");
  const handleSpinFinish = async (result: string) => {
    console.log(`Spun to: ${result}`);
    // Handle the result as needed
    setWinText(result);
    const { id } = await recordWin({ item: result });
    setWinId(`W${id}`);
    await winnerClaimed();
  };

  const uid = getUID();

  const candidate = useCandidate();
  // const segments = [
  //   { segmentText: "Option 1", segColor: "red" },
  //   { segmentText: "Option 2", segColor: "blue" },
  //   { segmentText: "Option 3", segColor: "green" },
  //   // Add more segments as needed
  // ];
  const [segments, setSegments] = useState<
    { segmentText: string; segColor: string }[]
  >([]);
  const [claimed, setClaimed] = useState(false);
  const spinWheelProps: ISpinWheelProps = {
    segments,
    onFinished: handleSpinFinish,
    primaryColor: "black",
    contrastColor: "white",
    buttonText: "Spin",
    isOnlyOnce: false,
    size: 180,
    upDuration: 100,
    downDuration: 600,
    fontFamily: "Arial",
    arrowLocation: "top",
    showTextOnSpin: true,
    isSpinSound: true,
  };

  const validateAppState = async () => {
    let uid = getUID();
    const { winnerJson, rewardItems } = await getQuizState();
    if (!winnerJson) {
      navigate("/walkin/interviewForm");
      return;
    }

    if (winnerJson.id !== uid) {
      navigate("/walkin/interviewForm");
      return;
    }

    // if (winnerJson.claimed) {
    //   // navigate("/walkin/interviewForm");
    //   setClaimed(true);
    //   return;
    // }
  };

  function shuffle(
    array: {
      segColor: string;
      segmentText: string;
    }[]
  ): void {
    let currentIndex = array.length;

    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    const colors = ["#d50f25", "#eeb211", "#009925", "#3369e8"];
    for (let i = 0; i < array.length; i++) {
      array[i].segColor = colors[i % colors.length];
    }
  }

  useEffect(() => {
    (async () => {
      const { winnerJson, rewardItems } = await getQuizState();
      await validateAppState();

      let segs = [];
      const colors = ["#d50f25", "#eeb211", "#009925", "#3369e8"];
      for (let i = 0; i < rewardItems.length; i++) {
        segs.push({
          segColor: colors[i % colors.length],
          segmentText: rewardItems[i],
        });
      }
      shuffle(segs);
      setSegments(segs);
    })();

    setInterval(() => {
      validateAppState();
    }, 3000);
  }, []);

  return (
    <SimpleIphonePromptLayout>
      <div style={{ color: "#e1436c", fontSize: "2em" }}>
        Congratulation! You are the winner
      </div>
      {claimed ? (
        <>
          <div>
            You have claimed winner previously, please wait for next round...
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to={"/walkin/interviewForm"}
            >
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#e1436c",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Go back and wait
              </div>
            </Link>
          </div>
        </>
      ) : (
        <>
          {winText ? (
            <></>
          ) : segments && segments.length > 0 ? (
            // <SpinWheel
            //   segments={segments}
            //   onFinished={handleSpinFinish}
            //   primaryColor={"black"}
            //   contrastColor={"white"}
            //   buttonText={"Spin"}
            //   isOnlyOnce={true}
            //   size={180}
            //   upDuration={100}
            //   downDuration={600}
            //   fontFamily={"Arial"}
            //   arrowLocation={"top"}
            //   showTextOnSpin={true}
            //   isSpinSound={true}
            // ></SpinWheel>
            <>
              <div>ID: {uid}</div>
              <div>Name: {candidate?.name}</div>
            </>
          ) : (
            <></>
          )}
          {winText ? (
            <>
              <div>Winner Name: {candidate?.name}</div>
              <div>Win ID: {winId}</div>
              <div></div>
              <div>You have won {winText} !</div>
              <div>Take a screenshot to claim later</div>
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "10px",
              width: "100%",
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              to={"/walkin/interviewForm"}
            >
              <div
                style={{
                  cursor: "pointer",
                  textAlign: "center",
                  color: "white",
                  backgroundColor: "#e1436c",
                  borderRadius: "10px",
                  padding: "10px",
                }}
              >
                Play Again
              </div>
            </Link>
          </div>
        </>
      )}
    </SimpleIphonePromptLayout>
  );
}
